<template>
	<r-dialog v-model="showDialog" max-width="600px" type="warning">
		<template v-slot:title>Delete Payment Method</template>

		<template v-if="!hasRecurrences">
			<p class="pt-4">
				Are you sure you want to delete this saved payment method?
			</p>
		</template>

		<template v-else-if="canReassignRecurrences">
			<p class="pt-4">
				This payment method is associated with a scheduled payment and cannot be deleted. You can reassign the scheduled payments to another saved payment method or you can cancel this recurring gift.
			</p>
			<p>
				What would you like to do?
			</p>
			<r-form ref="reassignForm">
				<r-radio-group v-model="reassignRecurrences" required>
					<div class="d-flex mb-4">
						<r-radio :value="true" class="mb-0 mr-4" label="Reassign to:" />
						<r-select-list v-model="reassignToPaymentMethodId" label="Payment Method" :options="reassignOptions" :required="reassignRecurrences" :disabled="!reassignRecurrences" :clearable="false" outlined hide-details dense />
					</div>
					<r-radio :value="false" label="Cancel recurring gift(s)" />
				</r-radio-group>
			</r-form>
		</template>

		<template v-else>
			<p class="pt-4">
				This payment method is associated with a scheduled payment and cannot be deleted until those gifts are canceled. Do you want to cancel those gifts and delete the saved payment method?
			</p>
		</template>

		<template v-slot:actions>
			<r-btn color="warning" @click="handleSubmit">Delete</r-btn>
			<r-btn class="ml-4" text @click="closeDialog">Close</r-btn>
		</template>
	</r-dialog>
</template>

<script>
export default {
	props: {
		value: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			reassignRecurrences: true,
			reassignToPaymentMethodId: null
		};
	},
	computed: {
		showDialog: {
			get: function() {
				return this.value !== null;
			},
			set: function(newValue) {
				if (newValue === false) {
					this.closeDialog();
				}
			}
		},
		paymentMethod() {
			return (
				_REALM.STORE.paymentMethodStore.state.paymentMethods.find(pm => pm.paymentMethodId === this.value) || {
					paymentMethodId: null,
					recurrences: []
				}
			);
		},
		reassignOptions() {
			return _REALM.STORE.paymentMethodStore.state.paymentMethods
				.filter(pm => pm.paymentMethodId !== this.value)
				.map(pm => ({
					text: pm.description,
					value: pm.paymentMethodId
				}));
		},
		hasRecurrences() {
			return this.paymentMethod.recurrences.length > 0;
		},
		canReassignRecurrences() {
			return this.hasRecurrences && this.reassignOptions.length > 0;
		}
	},
	watch: {
		reassignOptions: function(newOptions) {
			if (newOptions.length > 0) {
				this.reassignToPaymentMethodId = newOptions[0].value;
			} else {
				this.reassignToPaymentMethodId = null;
			}
		}
	},
	methods: {
		closeDialog() {
			this.$emit('input', null);
		},
		async handleSubmit() {
			if (!this.validate()) {
				return;
			}

			try {
				_REALM.LOADER.show();
				await this.processPaymentMethodDelete();

				let successMessage = `${this.paymentMethod.description} has been deleted`;
				if (this.canReassignRecurrences && this.reassignRecurrences) {
					successMessage += ' and gifts have been reassigned';
				}

				await _REALM.STORE.paymentMethodStore.listPaymentMethods(_REALM.STORE.givingFormStore.state.transactionAccount.mintCustomerId, true);
				this.closeDialog();
				_REALM.TOAST.success(successMessage);
			} finally {
				_REALM.LOADER.hide();
			}
		},
		async processPaymentMethodDelete() {
			if (this.hasRecurrences) {
				if (this.canReassignRecurrences && this.reassignRecurrences) {
					// Reassign recurrences
					await _REALM.STORE.paymentMethodStore.reassignRecurringGifts(this.value, this.reassignToPaymentMethodId);
				} else {
					// Cancel recurrences
					await _REALM.STORE.paymentMethodStore.cancelPaymentMethodRecurrences(this.value);
				}
			}

			// Delete Payment Method
			const individualId = _REALM.STORE.givingFormStore.state.transactionAccount.individualId;
			await _REALM.STORE.paymentMethodStore.deletePaymentMethod(this.value, individualId);
		},
		validate() {
			let dataIsValid = this.value !== null && this.paymentMethod.paymentMethodId !== null;

			if (this.canReassignRecurrences) {
				dataIsValid = dataIsValid && this.$refs.reassignForm.validate();
				if (this.reassignRecurrences) {
					dataIsValid = dataIsValid && this.reassignToPaymentMethodId !== null && this.reassignToPaymentMethodId !== this.value;
				}
			}

			return dataIsValid;
		}
	}
};
</script>
