<style lang="scss">
.payment-methods-grid {
	td {
		vertical-align: top;
	}
}
.text-caption {
	line-height: 1rem !important;
}
.recurrence-list > div:not(:last-child) {
	padding-bottom: 8px;
}
</style>

<template>
	<div class="payment-methods-grid">
		<r-btn class="mb-2" :to="{ name: 'new-payment-method', query: $route.query }">
			<v-icon>add</v-icon>
			&nbsp;Add Payment Method
		</r-btn>

		<r-table v-if="paymentMethodState.paymentMethods.length > 0" :headers="headers" :items="paymentMethodState.paymentMethods" :init-sort="{ descending: false, sortBy: 'name' }">
			<template slot-scope="{ items }">
				<tr v-for="item in items" :key="item.paymentMethodId">
					<td class="py-3">
						<img :src="getPaymentTypeImage(item)" />
					</td>
					<td class="py-3">
						<div>{{ item.description }}</div>
						<div v-if="item.description !== getDefaultDescription(item)">{{ getDefaultDescription(item) }}</div>
					</td>
					<td class="py-3">{{ item.expirationDate.isValid() ? item.expirationDate.format('MMM YYYY') : '' }}</td>
					<td class="py-3 billing-address">
						<div>{{ item.billingName }}</div>
						<div v-for="(line, index) in getBillingAddressLines(item)" :key="index" class="text-caption text--secondary">{{ line }}</div>
					</td>
					<td class="py-3 recurrence-list">
						<div v-for="recurrence in item.recurrences.filter(r => r.nextPaymentDate)" :key="recurrence.recurrenceId">
							<div v-for="(line, index) in getNextPaymentLines(recurrence)" :key="index">{{ line }}</div>
							<div class="text-caption text--secondary">
								{{ recurrenceStore.getRecurrenceFrequencyText(recurrence) }}
								<i v-if="recurrence.status === 'OnHold'">- On Hold</i>
							</div>
						</div>
					</td>
					<td class="py-3">
						<r-actions :item="item">
							<r-action-item :to="{ name: 'edit-payment-method', query: $route.query, params: { paymentMethodId: item.paymentMethodId } }">Edit</r-action-item>
							<r-action-item @click="() => (paymentMethodIdToDelete = item.paymentMethodId)">Delete</r-action-item>
						</r-actions>
					</td>
				</tr>
			</template>
		</r-table>
		<div v-else class="text-center mt-4">
			<h5 class="text-bold mb-2">No Payment Methods</h5>
			<p>There are currently no saved payment methods.</p>
		</div>

		<DeletePaymentMethodDialog v-model="paymentMethodIdToDelete" />
	</div>
</template>

<script>
import moment from 'moment';
import formatDollars from '@/filters/giving/Dollars';
import DeletePaymentMethodDialog from '@/components/giving/paymentMethods/DeletePaymentMethodDialog.vue';

const headers = [
	{ text: 'Type', value: 'img', sortable: false, width: '39px' },
	{ text: 'Description', value: 'description', sortable: false },
	{ text: 'Expiration Date', value: 'expirationDate', sortable: false },
	{ text: 'Billing Address', value: 'billingAddress', sortable: false },
	{ text: 'Next Scheduled Payment', value: 'nextPayment', sortable: false },
	{ text: '', value: 'actions', sortable: false, width: '80px' }
];

export default {
	components: {
		DeletePaymentMethodDialog
	},
	data() {
		return {
			paymentMethodState: _REALM.STORE.paymentMethodStore.state,
			recurrenceStore: _REALM.STORE.recurrenceStore,
			headers: headers,
			paymentMethodIdToDelete: null
		};
	},
	methods: {
		getPaymentTypeImage(paymentMethod) {
			const type = paymentMethod.paymentMethodType === 'Debit' || paymentMethod.paymentMethodType === 'Credit' ? paymentMethod.creditCardType : paymentMethod.paymentMethodType;

			return `/Assets/SPA/images/giving/card_${type}.png`;
		},
		getDefaultDescription(paymentMethod) {
			const typeString = paymentMethod.paymentMethodType === 'Checking' || paymentMethod.paymentMethodType === 'Savings' ? paymentMethod.paymentMethodType : paymentMethod.creditCardType || '';
			return `${typeString} ***${paymentMethod.last4}`;
		},
		getBillingAddressLines(paymentMethod) {
			const addressLines = [paymentMethod.billingAddress1];
			if (paymentMethod.billingAddress2) {
				addressLines.push(paymentMethod.billingAddress2);
			}

			addressLines.push(`${paymentMethod.billingCity}, ${paymentMethod.billingRegion} ${paymentMethod.billingPostalCode}`);
			return addressLines;
		},
		getNextPaymentLines(recurrence) {
			return recurrence.amounts.map(a => {
				const amount = formatDollars(a.amount);
				const nextDate = moment(recurrence.nextPaymentDate).format('MMMM D, YYYY');
				return `${amount} on ${nextDate}`;
			});
		}
	}
};
</script>
