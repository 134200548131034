<template>
	<v-layout v-if="!loading" row wrap justify-center>
		<v-col lg="9" xl="6">
			<r-card>
				<v-card-title>Payment Methods</v-card-title>
				<PaymentMethodsGrid class="pa-4" />
			</r-card>
		</v-col>
	</v-layout>
</template>

<script>
import PaymentMethodsGrid from '@/components/giving/paymentMethods/PaymentMethodsGrid.vue';

export default {
	components: {
		PaymentMethodsGrid
	},
	data() {
		return {
			loading: true
		};
	},
	async mounted() {
		_REALM.LOADER.show();
		const self = this;

		const individualId = self.$route.query.individualId || _REALM.STORE.accountStore.state.authAccount.individualId;
		await _REALM.STORE.givingFormStore
			.setTransactionAccount(individualId)
			.then(() => {
				const mintCustomerId = _REALM.STORE.givingFormStore.state.transactionAccount.mintCustomerId;
				return _REALM.STORE.paymentMethodStore.listPaymentMethods(mintCustomerId, true, false).then(() => {
					_REALM.LOADER.hide();
					self.loading = false;
				});
			})
			.catch(e => {
				_REALM.ERRORHANDLER.redirectToErrorPage(e);
			});
	}
};
</script>
